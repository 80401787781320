import { useContext } from 'react';

import { SidePanelContext } from '../SidePanelContext';

/*
  Use this hook instead of directly calling context in components
  for easier testing of the SidePanel context
*/
export function useSidePanelContext() {
  return useContext(SidePanelContext);
}
