import { DrawerProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { noop } from 'src/types/basic';

interface SidePanelState {
  isSidePanelVisible: boolean;
  content: ReactNode | null;
  drawerProps: DrawerProps;
  showSidePanel: (content: ReactNode, drawerProps?: DrawerProps) => void;
  hideSidePanel: () => void;
}

const initialState: SidePanelState = {
  isSidePanelVisible: false,
  content: null,
  drawerProps: {
    anchor: 'left',
  },
  showSidePanel: noop,
  hideSidePanel: noop,
};

export const SidePanelContext = React.createContext<SidePanelState>(
  initialState
);
